import React from 'react';

class Checkbox extends React.Component {
  onCheckedChanged(event) {
    if (this.props.onCheckedChanged) {
      this.props.onCheckedChanged(this.props.idx, event.target.checked);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <label className="checkbox">
        <div className="description">
          <i className="fa fa-exclamation-triangle hidden"></i>
          {
            this.props.textElements.map((element, idx) => {
              if (element.anchorLink) {
                return (
                  <a key={idx} target="_blank" rel="noopener noreferrer" href={element.anchorLink}>
                    {t(element.key)}
                  </a>
                );
              }
              return t(element.key);
            })
          }
        </div>
        <input type="checkbox" checked={this.props.checked} onChange={this.onCheckedChanged.bind(this)}/>
        <span className="checkmark"></span>
      </label>
    );
  }

  componentWillUnmount() {}
}

export default Checkbox;
