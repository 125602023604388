import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox.jsx';
import '../i18n.jsx';

class Checkboxes extends React.Component {
  /**
   * Should contain the panel specific checkbox texts to dynamically build checkboxes.
   */
  setups = {
    default: [
      [
        { key: 'optIn.iHaveReadTheTermsAndConditions1' },
        { key: 'termsAndConditions', anchorLink: window.GfK ? window.GfK.termsAndConditionsUrl : '' },
        { key: 'optIn.iHaveReadTheTermsAndConditions2' },
      ],
      [
        { key: 'optIn.iEnsureThatAllUsersWillBeInformed' },
      ],
      [
        { key: 'optIn.iDeclareThatIAmTheMainUser' },
      ],
    ],
  };

  constructor(props) {
    super(props);

    const checkboxElements = this.getCheckboxElements();
    const checkedStates = Array(checkboxElements.length).fill(false);

    this.state = {
      checkedStates,
      checkboxElements,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resetCheckboxSelection) {
      const checkedStates = Array(prevState.checkboxElements.length).fill(false);
      return { checkedStates };
    }
    return {};
  }

  getCheckboxElements() {
    // const x = this.props.t('checkboxes', { returnObjects: true });
    return this.setups.default;
  }

  onCheckedChanged(checkboxIdx, checked) {
    const { checkedStates } = this.state;
    checkedStates[checkboxIdx] = checked;
    this.setState({
      checkedStates,
    });

    if (this.props.onCheckedChanged) {
      const hasCheckboxesChecked = checkedStates.every((x) => x === true);
      this.props.onCheckedChanged(hasCheckboxesChecked);
    }
  }

  render() {
    return (
      <div>
        { this.state.checkboxElements.map((elements, idx) => <Checkbox
            key={idx}
            idx={idx}
            t={this.props.t}
            textElements={elements}
            checked={this.state.checkedStates[idx]}
            onCheckedChanged={this.onCheckedChanged.bind(this)} />)
        }
      </div>
    );
  }

  componentWillUnmount() {}
}

export default function ({ resetCheckboxSelection, onCheckedChanged }) {
  const { t } = useTranslation();
  return (
    <Checkboxes
      t={t}
      resetCheckboxSelection={resetCheckboxSelection}
      onCheckedChanged={onCheckedChanged}
    />);
}
