import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Checkboxes from '../Checkboxes/Checkboxes.jsx';
import Loader from '../Loader/Loader.jsx';

ReactDOM.render(
  (
    <Suspense fallback={<Loader />}>
      <Checkboxes />
    </Suspense>
  ), document.getElementById('checkboxes'),
);
