import React from 'react';

class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {}
}

export default Loader;
